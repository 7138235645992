import cn from 'classnames';
import { InfoActionCard } from '~/components/Info';
import { Mesra, MesraStatus } from '~/domain/card';
import { useVisibility } from '~/shared/hooks';
import { useZendeskTag } from '~/shared/hooks/useZendeskTag';
import { ZendeskTagsName } from '~/store';
import { VisibilityName } from '~/store/visibility';

type LinkOrActivateCardProps = Partial<
  Pick<Mesra, 'isPointRedemptionEnabled' | 'isPhysicalCard' | 'status'>
>;

export const LinkOrActivateCard = ({
  isPointRedemptionEnabled,
  isPhysicalCard,
  status,
}: LinkOrActivateCardProps) => {
  const zendeskTag = useZendeskTag();
  const linkMesraModal = useVisibility(VisibilityName.LINK_MESRA);
  const activateMesraModal = useVisibility(VisibilityName.ACTIVATE_MESRA);

  const openActivateMesraModal = () => {
    activateMesraModal.open();
    zendeskTag.set(ZendeskTagsName.LOYALTY_CARD_VIRTUAL_ACTIVATION);
  };
  const openLinkMesraModal = () => {
    linkMesraModal.open();
    zendeskTag.set(ZendeskTagsName.LOYALTY_CARD_VIRTUAL_ACTIVATION);
  };
  const canActivateRedemption =
    !isPointRedemptionEnabled &&
    status !== MesraStatus.FROZEN &&
    status !== MesraStatus.CLOSED &&
    status !== MesraStatus.TEMPORARILY_FROZEN;

  return (
    <div
      className={cn(
        'mb-10 mx-10 grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8',
        {
          hidden: !canActivateRedemption && isPhysicalCard,
        }
      )}
    >
      {canActivateRedemption && (
        <InfoActionCard
          title="Want to redeem points?"
          subtitle="Activate your card now to start redeeming Mesra points."
          actionText="Activate Now"
          icon={SpotGift}
          action={openActivateMesraModal}
        />
      )}
      {/* LY-927 */}
      {/**
       * temporarily disable link to physical feature
       * https://setelnow.slack.com/archives/C0128P7HHUZ/p1641182668166300?thread_ts=1639026450.110600&cid=C0128P7HHUZ
       * */}
      {!isPhysicalCard && status !== MesraStatus.CLOSED && (
        <InfoActionCard
          title="Have an existing Mesra Card?"
          subtitle="You can link & transfer points to the Setel app."
          actionText="Link & transfer points"
          icon={SpotMesra}
          action={openLinkMesraModal}
        />
      )}
    </div>
  );
};

const SpotGift = (props: { className?: string }) => (
  <img src="/assets/spot-gift.svg" alt="" {...props} />
);
const SpotMesra = (props: { className?: string }) => (
  <img src="/assets/spot-mesra.svg" alt="" {...props} />
);
