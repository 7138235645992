import { FC, useCallback, useEffect } from 'react';
import {
  MesraTransactionBalanceDetail,
  MesraTransactionMerchantDetail,
  MesraTransactionDateDetail,
  MesraTransactionTitleDetail,
  MesraTransactionDetailCard,
} from './MesraTransactionDetailInfo';
import { defaultOnError } from '~/domain/error';
import { useQuery } from 'react-query';
import {
  getTransactionsById,
  MesraTransactionItem,
  TransactionStatuses,
} from '~/domain/card';
import { ChatWithUs } from '~/features/ChatWithUs';
import { useZendeskTag } from '~/shared/hooks/useZendeskTag';
import { ZendeskTagsName } from '~/store';
import { useParams } from '@remix-run/react';

const MesraTransactionStatus = {
  [TransactionStatuses.FAILED]: ZendeskTagsName.LOYALTY_REDEEM_FAIL,
  [TransactionStatuses.SUCCESSFUL]: ZendeskTagsName.LOYALTY_REDEEM_PROCESSED,
  [TransactionStatuses.PENDING]: ZendeskTagsName.LOYALTY_REDEEM_PENDING,
};

export const MesraTransactionDetail: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const zendeskTag = useZendeskTag();

  const { id } = useParams();

  const { data } = useQuery(
    ['getTransactionsById', id],
    () => getTransactionsById(id!),
    {
      enabled: !!id,
      onError: defaultOnError,
    }
  );

  const setZendeskTag = useCallback(
    (data: MesraTransactionItem | undefined) => {
      if (!data) return;
      zendeskTag.set(MesraTransactionStatus[data.status]);
    },
    [zendeskTag]
  );

  useEffect(() => {
    setZendeskTag(data);
    return () => {
      zendeskTag.reset();
    };
  }, [data, setZendeskTag, zendeskTag]);

  return (
    <div className="flex justify-center items-center bg-gray-300 w-full md:px-5 md:py-20 md:rounded-lg">
      {data && (
        <div className="w-full md:max-w-xl">
          <MesraTransactionDetailCard>
            <MesraTransactionTitleDetail
              title={data.title}
              status={data.status}
            />
            <MesraTransactionBalanceDetail
              amount={data.amount}
              type={data.type}
              receiverBalance={data.receiverBalance}
              senderBalance={data.senderBalance}
            />
            <MesraTransactionMerchantDetail
              issuedBy={data.issuedBy}
              merchantName={data.merchantName}
              receiverCardNumber={data.receiverCardNumber}
              senderCardNumber={data.senderCardNumber}
            />
          </MesraTransactionDetailCard>
          <MesraTransactionDateDetail
            createdAt={data.createdAt}
            updatedAt={data.updatedAt}
          />
          <div className="flex w-full justify-center mt-16 mb-16 md:mt-10 md:mb-0">
            <ChatWithUs className="" />
          </div>
        </div>
      )}
    </div>
  );
};
