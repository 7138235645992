import { FC } from 'react';
import * as React from 'react';
import {
  MesraTransactionItem,
  prefixes,
  TransactionTypes,
} from '~/domain/card';
import { MesraTransactionDetailLine } from './MesraTransactionDetailLine';
import { MesraTransactionDetailSection } from './MesraTransactionDetailSection';
import { ProfileRoute } from '~/features/Profile/profile.const';
import { useBreadcrumb } from '~/shared/hooks';

export const MesraTransactionBalanceDetail: FC<
  React.PropsWithChildren<
    Pick<
      MesraTransactionItem,
      'amount' | 'type' | 'receiverBalance' | 'senderBalance'
    >
  >
> = ({ amount, type, receiverBalance, senderBalance }) => {
  const [pointPrefix, title] = prefixes(type);

  useBreadcrumb(
    type.includes(TransactionTypes.EARN) ||
      type.includes(TransactionTypes.EXTERNAL_EARN)
      ? [
          {
            label: 'Mesra history',
            link: ProfileRoute.MESRA_HISTORY,
          },
          { label: 'Point earning' },
        ]
      : [
          {
            label: 'Mesra history',
            link: ProfileRoute.MESRA_HISTORY,
          },
          { label: 'Redemption' },
        ]
  );

  return (
    <MesraTransactionDetailSection>
      <MesraTransactionDetailLine
        left={
          <>
            <span className="text-gray-light">{title}</span>
            <span>
              {pointPrefix}
              {new Intl.NumberFormat().format(amount)} Points
            </span>
          </>
        }
        right={
          <>
            <span className="text-gray-light">Mesra balance</span>
            <span className="">
              {receiverBalance || senderBalance
                ? `${new Intl.NumberFormat().format(
                    receiverBalance || senderBalance
                  )} Points`
                : '-'}
            </span>
          </>
        }
      />
    </MesraTransactionDetailSection>
  );
};
