import {
  MesraTransactionItem,
  TransactionTypes,
  TransactionStatuses,
  prefixes,
} from '~/domain/card';
import { DateTime } from 'luxon';
import { ProfileRoute } from '~/features/Profile/profile.const';
import { routeTo } from '~/shared/path';
import { InfoStatus } from '~/components/Info';
import { useNavigate } from '@remix-run/react';
import { useTranslation } from 'react-i18next';

export type TransactionEntryProps = {
  entry: Pick<
    MesraTransactionItem,
    'title' | 'amount' | 'type' | 'createdAt' | 'id' | 'status'
  >;
  prevEntry?: Pick<MesraTransactionItem, 'createdAt'>;
};

export const MesraTransactionLine = ({
  entry,
  prevEntry,
}: TransactionEntryProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const prevDate = prevEntry && DateTime.fromISO(prevEntry.createdAt);
  const currentDate = DateTime.fromISO(entry.createdAt);

  const [pointPrefix] = prefixes(entry.type);

  const route = [
    TransactionTypes.EARN,
    TransactionTypes.EXTERNAL_EARN,
  ].includes(entry.type)
    ? routeTo(ProfileRoute.MESRA_HISTORY_EARNINGS, { id: entry.id })
    : [
        TransactionTypes.REDEEM,
        TransactionTypes.EXTERNAL_REDEEM,
        TransactionTypes.REDEEM_CAPTURE,
        TransactionTypes.REDEEM_REVERSAL,
        TransactionTypes.REDEEM_VOID,
      ].includes(entry.type)
    ? routeTo(ProfileRoute.MESRA_HISTORY_REDEMPTION, { id: entry.id })
    : '';

  return (
    <>
      {prevDate?.month !== currentDate.month && (
        <div className="px-4 md:px-10 pt-8 md:pt-10 pb-4 font-medium bg-gray-100 border-b border-gray-border">
          {currentDate.toFormat('LLLL yyyy')}
        </div>
      )}
      <div
        className="p-4 md:px-10 border-gray-border text-gray-medium last:border-b-0 border-b hover:cursor-pointer hover:bg-gray-100"
        onClick={() => {
          navigate(route);
        }}
      >
        <div className="flex">
          <span className="flex-grow truncate">{entry.title}</span>
          <span className="justify-end font-medium flex-shrink-0 text-gray-dark">
            {`${pointPrefix}${new Intl.NumberFormat().format(entry.amount)}`}
            &nbsp; {t('mesraTransactionLine.points')}
          </span>
        </div>
        <div>
          {currentDate.toFormat('dd LLL')}{' '}
          {entry.status === TransactionStatuses.FAILED && (
            <InfoStatus
              className="inline-block align-text-bottom ml-3"
              variant="bg-error"
            >
              {t('mesraTransactionLine.failed')}
            </InfoStatus>
          )}
        </div>
      </div>
    </>
  );
};
