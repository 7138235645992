import { FC, useEffect, memo } from 'react';
import * as React from 'react';
import cn from 'classnames';
import barcode from 'jsbarcode';

export type BarcodeProps = {
  code?: string;
  faded?: boolean;
  className?: string;
};

export const Barcode: FC<React.PropsWithChildren<BarcodeProps>> = memo(
  ({ code, faded, className }) => {
    useEffect(() => {
      if (!code) {
        return;
      }

      barcode('#voucher-barcode', code, {
        margin: 0,
        height: 64,
        lineColor: 'currentColor',
        displayValue: false,
      });
    }, [code]);

    return (
      <svg
        height={64}
        id="voucher-barcode"
        className={cn(
          'transition-colors duration-500 ease-in-out',
          faded ? 'text-gray-faded' : 'text-gray-dark',
          className
        )}
      />
    );
  }
);
