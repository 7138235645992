import { IdentityType } from '~/domain/profile';
import * as Yup from 'yup';

export const idIdentifier = {
  idType: Yup.mixed().oneOf(Object.values(IdentityType)).required(),
  idNumber: Yup.string()
    .when('idType', {
      is: IdentityType.IC_NUMBER,
      then: Yup.string()
        .matches(
          /([0-9][0-9])((0[1-9])|(1[0-2]))((0[1-9])|([1-2][0-9])|(3[0-1]))([0-9][0-9])([0-9][0-9][0-9][0-9])/,
          'Invalid IC number'
        )
        .length(12, 'IC number should contains 12 numbers')
        .required('Required field'),
    })
    .when('idType', {
      is: IdentityType.PASSPORT,
      then: Yup.string()
        .matches(/^(?!^0+$)[a-zA-Z0-9]{6,9}$/, 'Invalid Passport Number')
        .required('Required field'),
    }),
};

export const mesraNumber = Yup.string()
  .length(17, 'Invalid Mesra card number')
  .required('Required field');
