import cn from 'classnames';
import { Mesra, MesraBalance } from '~/domain/card/card.type';
import { FC } from 'react';
import * as React from 'react';
import { MesraStatus } from './MesraStatus';
import { Barcode } from '~/features/Vouchers/VoucherDetails/Barcode';
import { useVariables } from '~/domain/variable';
import { AnimatedNumber } from './AnimatedNumber';

export type MesraCardProps = {
  card: Mesra;
  cardBalance?: MesraBalance;
  className?: string;
};

export const MesraCard: FC<React.PropsWithChildren<MesraCardProps>> = ({
  card,
  cardBalance,
  className,
}) => {
  const { status, cardNumber } = card;
  const { data: variables } = useVariables({ refetchOnMount: true });
  const showMesraBarcode =
    !!variables?.setel_web_mesra_card_barcode_visibility?.value;

  return (
    <div
      className={cn(
        'flex flex-col justify-center md:justify-start mt-4',
        className
      )}
    >
      <img
        className="h-16 md:h-32 object-contain"
        alt="mesra-card"
        src="/assets/mesra-card.png"
        srcSet={`/assets/mesra-card@2x.png 2x,
            /assets/mesra-card@3x.png 3x`}
      />
      <MesraStatus status={status} />
      <span className="text-[2.2rem] sm:text-[2.75rem] text-center break-all font-medium cursor-pointer">
        {cardBalance?.pointTotalBalance ? (
          <AnimatedNumber number={cardBalance.pointTotalBalance} />
        ) : (
          '-'
        )}
      </span>
      <span className="text-center">points</span>
      {showMesraBarcode && (
        <Barcode className="w-full mt-4" code={`${cardNumber}-web`} />
      )}
    </div>
  );
};
