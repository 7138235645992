import cn from 'classnames';
import { MesraStatus as MesraStatusEnum } from '~/domain/card/card.type';
import startCase from 'lodash-es/startCase';

export type MesraStatusProps = {
  status?: MesraStatusEnum;
};

export const MesraStatus = ({ status }: MesraStatusProps) => {
  return (
    <span className="pt-2 md:pt-3 text-center w-full inline-block align-middle">
      <span
        className={cn(
          'px-[6px] py-[1px]',
          'text-xs leading-[1.125rem] font-bold uppercase tracking-[.12em]',
          'inline-flex items-center rounded-md text-white',
          {
            'bg-[#2ECC71]': status === MesraStatusEnum.ACTIVE,
            'bg-[#BCBFC5]': status !== MesraStatusEnum.ACTIVE,
          }
        )}
      >
        {startCase(status)}
      </span>
    </span>
  );
};
