import { FC } from 'react';
import * as React from 'react';
import cn from 'classnames';

export type MesraInfoLineProps = {
  label: string;
  value: string | number;
  cardNumber?: boolean;
};

export const MesraInfoLine: FC<React.PropsWithChildren<MesraInfoLineProps>> = ({
  cardNumber,
  label,
  value,
}) => {
  return (
    <div className="flex my-2 justify-center md:justify-start px-10 md:px-0">
      <span
        className={cn('flex-grow md:flex-grow-0 md:w-1/2 text-gray-light', {
          'hidden md:block': cardNumber,
        })}
      >
        {label}
      </span>
      <span
        className={cn('text-gray-dark capitalize', {
          'md:text-left text-lg md:text-regular': cardNumber,
        })}
      >
        {value}
      </span>
    </div>
  );
};
