import { FC } from 'react';
import * as React from 'react';
import { MesraTransactionItem } from '~/domain/card';
import { MesraTransactionDetailLine } from './MesraTransactionDetailLine';
import { DateTime } from 'luxon';

export type MesraTransactionDateDetailProps = {
  createdAt: string;
  updatedAt: string;
};

export const MesraTransactionDateDetail: FC<
  React.PropsWithChildren<Pick<MesraTransactionItem, 'createdAt' | 'updatedAt'>>
> = ({ createdAt, updatedAt }) => {
  const issuedDate = DateTime.fromISO(createdAt);
  const updatedDate = DateTime.fromISO(updatedAt);
  return (
    <div className="relative p-4 jagged-bottom-3 jagged-bottom-gray-100 bg-gray-100">
      <MesraTransactionDetailLine
        left={
          <>
            <span className="text-gray-light">Issued on</span>
            <span>{issuedDate.toFormat('d LLLL, yyyy')}</span>
            <span>{issuedDate.toFormat('hh:mm:ss a')}</span>
          </>
        }
        right={
          <>
            <span className="text-gray-light">Updated on</span>
            <span>{updatedDate.toFormat('d LLLL, yyyy')}</span>
            <span>{updatedDate.toFormat('hh:mm:ss a')}</span>
          </>
        }
      />
    </div>
  );
};
