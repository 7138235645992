import { IdIdentifier, SearchMesraResponse } from '~/domain/card';
import { useEffect, useState } from 'react';
import type { Slider as SlickSlider } from '~/components/Slick';
import { VisibilityName } from '~/store';
import { getConnectionFlow } from '../ConnectMesra';
import { LinkMesra } from './LinkMesra';
import { SearchMesra } from './SearchMesra';
import { ValidateMesra } from './ValidateMesra';
import { ContactSupport } from '../Register/ContactSupport';
import multipleSvg from '../Register/assets/customer-service2.svg';
import customerServiceSvg from '../Register/assets/customer-services.svg';

export enum ConnectFlow {
  ACTIVATE = VisibilityName.ACTIVATE_MESRA,
  LINK = VisibilityName.LINK_MESRA,
}

export type UseStepsParam = {
  slider: React.RefObject<SlickSlider>;
  onClose(): void;
  flow: ConnectFlow;
};

export const useSteps = ({ slider, onClose, flow }: UseStepsParam) => {
  const ActivationFlow = getConnectionFlow({
    flow,
    onOk: onClose,
    onActivated: () => slider.current?.slickNext(),
  });

  const onSuccessSearch = async (response: SearchMesraResponse) => {
    switch (response.cardsFound) {
      case 0:
        processVirtualActivation();
        break;
      // TODO: temp hide https://setelnow.atlassian.net/browse/GA-767
      case 1:
        processLinkPhysical(response);
        break;
      default:
        processMultipleAcc();
    }
    slider.current?.slickNext();
  };

  const processMultipleAcc = () => {
    setData((data) => ({
      ...data,
      showBack: false,
      steps: [
        ...InitialSteps,
        <ContactSupport
          title="We found multiple Mesra card linked to you"
          svgUrl={multipleSvg}
          key="multi_mesra"
        />,
      ],
    }));
  };

  const processVirtualActivation = () => {
    setData((data) => ({
      ...data,
      steps: [...InitialSteps, ...ActivationFlow],
    }));
  };

  const processLinkPhysical = (idIdentifier: IdIdentifier) => {
    const onBack = () => {
      setData((data) => ({
        ...data,
        autoGoNext: false,
        onBack: () => slider.current?.slickPrev(),
        steps: data.steps.slice(0, -1),
      }));
    };

    const nextSteps = ({
      steps,
      showBack,
    }: {
      steps: JSX.Element[];
      showBack: boolean;
    }) =>
      setData((data) => ({
        ...data,
        onBack,
        showBack,
        autoGoNext: true,
        steps: [...data.steps, ...steps],
      }));

    setData((data) => ({
      ...data,
      showBack: true,
      steps: [
        ...InitialSteps,
        <ValidateMesra
          key="found"
          idIdentifier={idIdentifier}
          onContinue={() =>
            nextSteps({ steps: ActivationFlow, showBack: false })
          }
          onStayWithVirtual={() =>
            nextSteps({
              showBack: true,
              steps: [
                <ContactSupport
                  title="Contact us to proceed with virtual card activation"
                  svgUrl={customerServiceSvg}
                  key="stay_with_virtual"
                />,
              ],
            })
          }
        />,
      ],
    }));
  };

  const onSuccessLink = () => {
    setData((data) => ({
      ...data,
      showBack: false,
      steps: [...InitialSteps, ...ActivationFlow],
    }));

    slider.current?.slickNext();
  };

  const InitialSteps = [
    flow === ConnectFlow.ACTIVATE ? (
      <SearchMesra key="search_mesra" onSuccess={onSuccessSearch} />
    ) : (
      <LinkMesra key="search_mesra" onSuccess={onSuccessLink} />
    ),
  ];
  const [data, setData] = useState({
    showBack: false,
    autoGoNext: false,
    onBack: () => slider.current?.slickPrev(),
    steps: InitialSteps,
  });

  useEffect(() => {
    if (data.autoGoNext) slider.current?.slickNext();
  }, [slider, data.autoGoNext]);

  const hideBackBtn = () => setData((data) => ({ ...data, showBack: false }));

  return { ...data, hideBackBtn };
};
