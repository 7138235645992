import { FC, ReactNode } from 'react';
import * as React from 'react';
import cn from 'classnames';

export type MesraTransactionDetailLineProps = {
  left?: ReactNode;
  right?: ReactNode;
  className?: string;
};

export const MesraTransactionDetailLine: FC<
  React.PropsWithChildren<MesraTransactionDetailLineProps>
> = ({ left, right, className }) => {
  return (
    <div className={cn('flex w-full', className)}>
      <div className="flex flex-col w-1/2">{left}</div>
      <div className="flex flex-col items-end w-1/2">{right}</div>
    </div>
  );
};
