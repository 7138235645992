import { useInfiniteQuery } from 'react-query';
import { defaultOnError } from '~/domain/error';
import { getTransactions } from '~/domain/card';

export const useListMesraHistory = () => {
  const { data, ...query } = useInfiniteQuery(
    'getTransactions',
    ({ pageParam: nextToken }: { pageParam?: string }) =>
      getTransactions({ nextToken }),
    {
      onError: defaultOnError,
      getNextPageParam: (lastData) => lastData.nextToken,
    }
  );

  const transactions = data?.pages.flatMap((resp) => resp.data);

  return {
    data: data?.pages,
    transactions,
    ...query,
    hasHistory: Boolean(transactions?.length),
  };
};
