import { Card } from '~/components/Card';
import { MesraHistoryContent } from './MesraHistoryContent';
import { useListMesraHistory } from './useListMesraHistory';
import { useZendeskTagEffect } from '~/shared/hooks/useZendeskTag';
import { ZendeskTagsName } from '~/store';
import { ProfileContentHeader } from '~/features/Profile/ProfileContentHeader';
import { useTranslation } from 'react-i18next';

export const MesraHistory = () => {
  const { t } = useTranslation();
  useZendeskTagEffect(ZendeskTagsName.LOYALTY_TRANSACTION_LIST);

  const { hasHistory } = useListMesraHistory();

  return (
    <div className={'py-1 md:py-0'}>
      <Card overwriteWidth hideBorderOnMobile={!hasHistory} className="">
        <ProfileContentHeader title={t('profileMesraHistoryTitle')} />
        <MesraHistoryContent />
      </Card>
    </div>
  );
};
