import { Icon } from '~/components';
import { Size } from '~/components/theme';
import { FadeInDown } from '~/components/Transition';
import * as cardService from '~/domain/card';
import { Mesra } from '~/domain/card';
import { defaultOnError } from '~/domain/error';
import { useIsAuthorized } from '~/features/Auth';
import { FC, useEffect, useState } from 'react';
import * as React from 'react';
import { useQuery } from 'react-query';
import { useIsNewlyRegistered, useVisibility } from '~/shared/hooks';
import { useZendeskTag } from '~/shared/hooks/useZendeskTag';
import { ZendeskTagsName } from '~/store';
import { VisibilityName } from '~/store/visibility';

const REFETCH_INTERVAL = 2000;
const MAX_RETRY = 1;
export const LinkMesraNotification = () => {
  const isAuthorized = useIsAuthorized();
  const isNewRegisteredUser = useIsNewlyRegistered();

  const zendeskTag = useZendeskTag();

  const [errorCount, setErrorCount] = useState(0);
  const [enabled, setEnabled] = useState(false);
  const [hideBar, setHidebar] = useState(true);

  useEffect(() => {
    const id = setTimeout(() => setEnabled(true), REFETCH_INTERVAL);
    return () => clearTimeout(id);
  }, []);

  const errorRecieved = (err: any) => {
    setErrorCount(errorCount + 1);
    defaultOnError(err);

    if (!isNewRegisteredUser || errorCount >= MAX_RETRY) {
      setEnabled(false);
    }
  };

  const { data, isLoading } = useQuery('getCard', cardService.getCard, {
    onError: errorRecieved,
    refetchInterval: REFETCH_INTERVAL,
    enabled: isAuthorized && enabled,
    onSuccess: (response: Mesra) => {
      if (response) {
        setEnabled(false);
        setHidebar(false);
      }
    },
  });

  useEffect(() => {
    setNotificationVisibility(
      cardService.isLinkMesraNotificationVisible(data?.id)
    );
  }, [data]);

  const isPhysicalCard = !!(data && data.isPhysicalCard);
  const isPointRedemptionEnabled = !!(data && data.isPointRedemptionEnabled);

  const [notificationVisibility, setNotificationVisibility] = useState(false);

  const linkMesraModal = useVisibility(VisibilityName.LINK_MESRA);
  const activateMesraModal = useVisibility(VisibilityName.ACTIVATE_MESRA);

  const openLinkMesraModal = () => {
    linkMesraModal.open();
    zendeskTag.set(ZendeskTagsName.LOYALTY_CARD_VIRTUAL_ACTIVATION);
  };

  const openActivateMesraModal = () => {
    activateMesraModal.open();
    zendeskTag.set(ZendeskTagsName.LOYALTY_CARD_VIRTUAL_ACTIVATION);
  };

  if (isLoading || (isNewRegisteredUser && hideBar) || !isAuthorized)
    return null;

  const showLinkCard = notificationVisibility && !isPhysicalCard;
  const showActivateCard =
    !showLinkCard &&
    (!notificationVisibility || isPhysicalCard) &&
    !isPointRedemptionEnabled;

  const onCloseNotification = () => {
    const cardId = data?.id;
    cardService.hideLinkMesraNotification(cardId);
    setNotificationVisibility(false);
  };

  return (
    <FadeInDown show={showLinkCard || showActivateCard}>
      <div className="relative md:w-full bg-white rounded-lg mb-8 mx-5 md:mx-0 flex flex-col shadow">
        <div className="p-4 flex justify-start text-gray-dark text-center md:text-left">
          <div className="w-16 h-12">
            <div className="inline-block p-2 mr-4 rounded-lg bg-brand-50">
              <Icon
                className="inline w-6 text-brand"
                name="bell"
                size={Size.SMALL}
              />
            </div>
          </div>
          {showLinkCard && (
            <GetSticker
              onClickFn={openLinkMesraModal}
              onCloseNotification={onCloseNotification}
              titleText="Do you have a physical Mesra card? Link your card with Setel and redeem deals now"
              yesBtnText="Yes, link now"
              noBtnText="No, I don't"
            />
          )}

          {showActivateCard && (
            <GetSticker
              onClickFn={openActivateMesraModal}
              onCloseNotification={onCloseNotification}
              titleText="Activate your Mesra card now and redeem deals today!"
              yesBtnText="Activate now"
            />
          )}
        </div>
      </div>
    </FadeInDown>
  );
};

export type StickerProps = {
  onClickFn: any;
  onCloseNotification: any;
  titleText: string;
  yesBtnText: string;
  noBtnText?: string;
};

const GetSticker: FC<React.PropsWithChildren<StickerProps>> = ({
  onClickFn,
  onCloseNotification,
  titleText,
  yesBtnText,
  noBtnText,
}) => {
  return (
    <p className="md:flex w-full self-center justify-between text-left">
      <span className="block">{titleText}</span>
      <span className="block md:inline-block mt-3 md:mt-0 mr-5">
        <button
          className="text-brand mr-2 focus:outline-none"
          onClick={onClickFn}
        >
          {yesBtnText}
        </button>
        {noBtnText && (
          <>
            &middot;
            <button
              className="text-brand ml-2 focus:outline-none"
              onClick={onCloseNotification}
            >
              {noBtnText}
            </button>
          </>
        )}
      </span>
    </p>
  );
};
