import { Modal } from '@setel/web-ui';
import { FC, useRef } from 'react';
import type { Slider } from '~/components/Slick';
import { useVisibility } from '~/shared/hooks';
import { ConnectFlow, useSteps } from './useSteps';
import { Steps } from './Steps';

export type ConnectModalProps = {
  flow: ConnectFlow;
};
export const ConnectModal: FC<React.PropsWithChildren<ConnectModalProps>> = ({
  flow,
}) => {
  const activateMesraModal = useVisibility(flow as any);

  const slider = useRef<Slider>(null);

  const { onBack, showBack, ...steps } = useSteps({
    slider,
    flow,
    onClose: activateMesraModal.close,
  });

  return (
    <Modal
      aria-label="Connect to Mesra"
      isOpen={activateMesraModal.isOpen}
      onDismiss={activateMesraModal.close}
      onBack={showBack ? onBack : undefined}
      noHeaderBorder
    >
      <Modal.Header>
        {/* As per design, no title, but need nav buttons */}
      </Modal.Header>
      <Modal.Body>
        <Steps {...steps} ref={slider} />
      </Modal.Body>
    </Modal>
  );
};
