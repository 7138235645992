import { openZendeskWidget } from '~/features/Zendesk';
import { BareButton } from '@setel/web-ui';

type ChatWithUsProps = {
  className?: string;
};

type ChatWithUsAndTelProps = {
  className?: string;
  classNameTel?: string;
};

export const ChatWithUs = ({ className }: ChatWithUsProps) => {
  const zendesk = () => openZendeskWidget();
  return (
    <>
      <BareButton className={className} onClick={zendesk}>
        Need help? Chat with us now
      </BareButton>
    </>
  );
};

export const ChatWithUsAndTel = ({
  className,
  classNameTel = 'pt-5',
}: ChatWithUsAndTelProps) => {
  const zendesk = () => openZendeskWidget();
  return (
    <>
      <BareButton className={className} onClick={zendesk}>
        Chat with us
      </BareButton>
      <div className={classNameTel}>
        <a
          href={'tel:+601300228888'}
          className="text-[#00B0FF] text-base font-medium leading-6"
          style={{ textDecoration: 'none' }}
        >
          Call 1-300-22-8888
        </a>
      </div>
    </>
  );
};
