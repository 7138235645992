import { MesraStatus, TransactionStatuses } from '../card/card.type';

export enum PubsubService {
  Points = 'points',
}

export enum PubsubChannel {
  ReadEarning = 'readEarning',
}

export enum PubsubScope {
  User = 'user',
}

export interface PubsubSubscribeParams {
  service: PubsubService;
  operationId: PubsubChannel;
  scope: PubsubScope;
  userId?: string;
  params?: Record<string, any>;
}

export interface PubsubSubscribeResponse extends PubsubSubscribeParams {
  subUrl: string;
}

interface MembershipTier {
  level: number;
  name: string;
}

export interface ReadEarningEventResponse {
  referenceId: string;
  status: TransactionStatuses;
  amount: number;
  pointsAmount: number;
  membership: {
    currentTier: MembershipTier;
    nextTier: MembershipTier;
    membershipIconPrimary: string;
    membershipIconDetails: string;
    pointsForLevelUp: number;
  };
  cardNumber: string;
  cardStatus: MesraStatus;
}
