import { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';
import { pubsubSubscribe } from './pubsub.service';
import { PubsubSubscribeParams, PubsubSubscribeResponse } from './pubsub.type';

export const usePubsubSubscribe = <Result = PubsubSubscribeResponse>(
  values: PubsubSubscribeParams,
  config?: UseQueryOptions<any, AxiosError<any>, Result, string[]>
) =>
  useQuery(['pubsub'], () => pubsubSubscribe(values), {
    ...config,
    refetchOnWindowFocus: config?.refetchOnWindowFocus || false,
    refetchOnMount: config?.refetchOnMount || true,
  });
