import { FC } from 'react';
import * as React from 'react';
import { Info } from '~/components/Info';
import { ChatWithUsAndTel } from '~/features/ChatWithUs';

export type ContactSupportProps = {
  title: string;
  svgUrl: string;
};

export const ContactSupport: FC<
  React.PropsWithChildren<ContactSupportProps>
> = ({ title, svgUrl }) => (
  <div className="flex flex-col items-center">
    <Info
      className="my-0 mb-4"
      largeTitle
      svg={() => CustomerServices(svgUrl)}
      title={title}
      subTitle="Please chat with us or contact the Mesralink hotline for further assistance."
    />
    <ChatWithUsAndTel className="mt-6" />
  </div>
);

const CustomerServices = (svgUrl: string) => <img src={svgUrl} alt="" />;
