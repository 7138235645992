import { FC, useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';

interface IProps {
  number: number;
  isFormatted?: boolean;
  fractionDigits?: number;
  renderOnmount?: boolean;
}

export const AnimatedNumber: FC<IProps> = (props) => {
  const {
    fractionDigits = 0,
    isFormatted = true,
    renderOnmount = false,
  } = props;

  const [prevNumber, setPrevNumber] = useState(
    renderOnmount ? 0 : props.number
  );

  const { number } = useSpring({
    from: { number: prevNumber },
    number: props.number,
  });

  useEffect(() => {
    setPrevNumber(props.number);
  }, [props.number]);

  return (
    <animated.div className="number">
      {number.to((n) =>
        isFormatted
          ? new Intl.NumberFormat(undefined, {
              maximumFractionDigits: fractionDigits,
            }).format(n)
          : n.toFixed(fractionDigits)
      )}
    </animated.div>
  );
};
