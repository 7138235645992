import { Button } from '@setel/web-ui';
import { Info, EmptyVoucherIcon } from '~/components';

type ConnectionSuccessfulProps = {
  title: string;
  onOk(): void;
};

export const ConnectionSuccessful = ({
  title,
  onOk,
}: ConnectionSuccessfulProps) => (
  <div className="flex flex-col items-center px-2">
    <Info
      largeTitle
      svg={EmptyVoucherIcon}
      title={title}
      subTitle="You can now start redeeming points from your Mesra card"
    />
    <Button onClick={onOk} className="mt-4 w-full">
      OK
    </Button>
  </div>
);
