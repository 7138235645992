import { Processing } from './Processing';
import { ConnectionSuccessful } from './ConnectionSuccessful';
import { ConnectFlow } from './useSteps';

export const getConnectionFlow = ({
  onActivated,
  onOk,
  flow,
}: {
  onActivated: () => void;
  onOk: () => void;
  flow: ConnectFlow;
}) => {
  let connectionFlow: JSX.Element[] = [];

  if (flow === ConnectFlow.ACTIVATE)
    connectionFlow.push(
      <Processing key="process_activation" onActivated={onActivated} />
    );

  connectionFlow.push(
    <ConnectionSuccessful
      title={
        flow === ConnectFlow.ACTIVATE
          ? 'Mesra card activated!'
          : 'Mesra card linked!'
      }
      onOk={onOk}
      key="mesra_linked"
    />
  );

  return connectionFlow;
};
