import * as React from 'react';
import { CardTitle } from '~/components/Card';

type Props = { title: string };

export const ProfileContentHeader: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  children,
}) => (
  <div className="flex justify-between">
    <CardTitle className="hidden md:block border-b border-gray-border w-full py-6 pl-10 font-medium text-base md:text-article-subtitle">
      {title}
    </CardTitle>
    <div className="py-5 pr-10 border-b border-gray-border">{children}</div>
  </div>
);
