import { Input, Select } from '~/components';
import { useQueryClient } from 'react-query';
import {
  switchToPhysicalCard,
  SwitchToPhysicalCardParams,
} from '~/domain/card';
import { defaultOnError, onlyValidationError } from '~/domain/error';
import { IdentityType, IdentityTypeLabel } from '~/domain/profile';
import { FormField } from '~/features/Formik';
import { FormikErrors, useFormik } from 'formik';
import { Button } from '@setel/web-ui';
import * as Yup from 'yup';
import { idIdentifier, mesraNumber } from './validation';
import { useUserProfile } from '~/features/Auth/hooks';

type LinkMesraProps = {
  onSuccess(): void;
  initialErrors?: FormikErrors<SwitchToPhysicalCardParams>;
};

const validationSchema = Yup.object().shape({
  ...idIdentifier,
  cardNumber: mesraNumber,
});

export const LinkMesra = ({ onSuccess, initialErrors }: LinkMesraProps) => {
  const { data: profile } = useUserProfile();
  const queryCache = useQueryClient();
  const formik = useFormik({
    initialValues: {
      idType: profile?.identityType || IdentityType.IC_NUMBER,
      idNumber: profile?.identityNumber || '',
      cardNumber: '',
    },
    validationSchema,
    initialErrors,
    onSubmit: (data) =>
      switchToPhysicalCard(data)
        .then(() => {
          onSuccess();
          queryCache.invalidateQueries('getCard');
        })
        .catch(
          onlyValidationError(({ fields }) => {
            formik.setErrors(fields);
          })
        )
        .catch(defaultOnError),
  });

  const idTypeOptions = Object.values(IdentityType).map((value) => ({
    value,
    label: IdentityTypeLabel[value],
  }));

  const idNumberLabel = idTypeOptions.find(
    ({ value }) => value === formik.values.idType
  )?.label;

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="flex flex-col items-center px-2"
    >
      <h1 className="text-2xl text-gray-800 text-center font-medium mb-4">
        Link Setel to your existing Mesra card
      </h1>

      <FormField
        {...formik}
        label="Mesra card number"
        name="cardNumber"
        placeholder="Enter mesra card number"
        component={Input}
      />

      <FormField
        {...formik}
        label="ID type"
        name="idType"
        componentParams={{
          options: idTypeOptions,
        }}
        useSetValue
        component={Select}
      />
      <FormField
        {...formik}
        label={idNumberLabel}
        name="idNumber"
        placeholder="Ex:128271291291"
        component={Input}
      />
      <Button
        isLoading={formik.isSubmitting}
        className="mt-6 mb-4 w-full"
        disabled={!formik.isValid}
        type="submit"
      >
        Continue
      </Button>
    </form>
  );
};
