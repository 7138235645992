import { forwardRef } from 'react';
import { Slider } from '~/components/Slider';
import type { Slider as SlickSlider } from '~/components/Slick';

export type StepsProps = {
  autoGoNext: boolean;
  steps: JSX.Element[];
};

export const Steps = forwardRef<SlickSlider, StepsProps>(({ steps }, ref) => {
  return (
    <Slider
      lazyLoad="ondemand"
      ref={ref}
      adaptiveHeight
      swipe={false}
      arrows={false}
      infinite={false}
    >
      {steps}
    </Slider>
  );
});
