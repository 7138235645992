import { Info, EmptyVoucherIcon } from '~/components';
import { Loader } from '@setel/web-ui';
import { LoadMore } from '~/components/LoadMore';
import { MesraTransactionLine } from './MesraTransactionLine';
import { useListMesraHistory } from './useListMesraHistory';
import { useTranslation } from 'react-i18next';

export const MesraHistoryContent = () => {
  const { t } = useTranslation();
  const {
    transactions,
    fetchNextPage: fetchMore,
    hasNextPage: canFetchMore,
    isFetchingNextPage: isFetchingMore,
    isLoading,
    hasHistory,
  } = useListMesraHistory();

  if (isLoading) {
    return (
      <div className="h-screen-1/2 flex justify-center">
        <Loader type="spinner-blue" />
      </div>
    );
  }

  if (!hasHistory)
    return (
      <div className="flex h-full justify-center items-center px-5 py-0 md:py-20">
        <Info
          largeTitle
          svg={EmptyVoucherIcon}
          title={t('mesraHistoryContent.noHistoryTitle')}
          subTitle={t('mesraHistoryContent.noHistorySubtitle')}
        />
      </div>
    );

  return (
    <div className="flex-row p-0">
      {transactions?.map((value, index) => (
        <MesraTransactionLine
          key={index}
          entry={value}
          prevEntry={transactions && transactions[index - 1]}
        />
      ))}
      <LoadMore
        className="py-6 md:py-10"
        onFetchMore={fetchMore}
        loading={Boolean(isFetchingMore)}
        show={Boolean(canFetchMore)}
      />
    </div>
  );
};
