import { FC, ReactNode } from 'react';
import * as React from 'react';
import cn from 'classnames';

export type MesraTransactionDetailSectionProps = {
  className?: string;
  children?: ReactNode;
};

export const MesraTransactionDetailSection: FC<
  React.PropsWithChildren<MesraTransactionDetailSectionProps>
> = ({ className, children }) => {
  return (
    <div
      className={cn('p-4 border-b border-gray-300 border-dashed', className)}
    >
      {children}
    </div>
  );
};
