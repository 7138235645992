import { FC } from 'react';
import * as React from 'react';

export const MesraTransactionDetailCard: FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  return (
    <div className="w-full md:max-w-xl">
      <div className="border-0 rounded-t-xtra rounded-b-none flex flex-col bg-white">
        {children}
      </div>
    </div>
  );
};
