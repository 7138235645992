import cn from 'classnames';
import { IExpiryDates, Mesra } from '~/domain/card';
import { FC } from 'react';
import * as React from 'react';
import { MesraInfoLine } from './MesraInfoLine';
import { DateTime } from 'luxon';

export type MesraInfoProp = Mesra & {
  pointExpiryDates?: IExpiryDates[];
  className?: string;
};

export const MesraInfo: FC<React.PropsWithChildren<MesraInfoProp>> = ({
  className,
  cardNumber,
  createdAt,
  issuedBy,
  isPhysicalCard,
  isPointEarningEnabled,
  pointExpiryDates,
}) => {
  const listExpiry3Month = getList3MonthsPointExpiry(pointExpiryDates);

  return (
    <div
      className={cn(
        'flex flex-col w-full text-regular leading-normal',
        className
      )}
    >
      <MesraInfoLine
        cardNumber
        label="Mesra number"
        value={cardNumber.replace(/(\d{7})(\d{5})(\d{5})/, '$1-$2-$3')}
      />
      <MesraInfoLine
        label="Linked on"
        value={DateTime.fromISO(createdAt).toFormat('dd LLL yyyy')}
      />
      <MesraInfoLine label="Issued by" value={issuedBy} />
      <MesraInfoLine
        label="Type"
        value={isPhysicalCard ? 'Physical' : 'Virtual'}
      />
      <MesraInfoLine
        label="Earn points"
        value={isPointEarningEnabled ? 'Yes' : 'No'}
      />
      <div className="flex my-2 justify-center md:justify-start px-10 md:px-0">
        <span className="flex-grow md:flex-grow-0 md:w-1/2 text-gray-light">
          Point expiries
        </span>
        {listExpiry3Month.length > 0 ? (
          <div>
            {listExpiry3Month?.map((expiryDate) => (
              <div className="text-gray-dark mb-4" key={expiryDate}>
                {expiryDate}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

function getList3MonthsPointExpiry(pointExpiryDates?: IExpiryDates[]) {
  let listExpiry3Month: string[] = [];
  const list3Month = [
    DateTime.now().toISODate(),
    DateTime.now().plus({ months: 1 }).toISODate(),
    DateTime.now().plus({ months: 2 }).toISODate(),
  ];

  list3Month.forEach((value) => {
    let isReturn = false;
    const month = DateTime.fromISO(value).toFormat('LLL yyyy');
    pointExpiryDates?.forEach((value) => {
      if (
        DateTime.fromISO(value.expiringPointsDate).toFormat('LLL yyyy') ===
        month
      ) {
        listExpiry3Month.push(`${month} - ${value.expiringPointsAmount} pts`);
        isReturn = true;
      }
    });
    if (isReturn === false) {
      listExpiry3Month.push(`${month} - 0 pts`);
    }
  });
  return listExpiry3Month;
}
