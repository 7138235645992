import { Card, CardContent } from '~/components/Card';
import { TransactionStatuses, getCard, getMesraBalance } from '~/domain/card';
import { defaultOnError } from '~/domain/error';
import { ChatWithUs } from '~/features/ChatWithUs';
import { MesraCard } from '~/features/Mesra/MesraSummary/MesraCard';
import { MesraInfo } from '~/features/Mesra/MesraSummary/MesraInfo';
import { FC, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useZendeskTagEffect } from '~/shared/hooks/useZendeskTag';
import { ZendeskTagsName } from '~/store';
import { LinkOrActivateCard } from './LinkOrActivateCard';
import { ProfileContentHeader } from '~/features/Profile/ProfileContentHeader';
import { usePubsubSubscribe } from '~/domain/pubsub/pubsub.queries';
import { useLoginProfile } from '~/features/Auth/hooks/useUserProfile';
import {
  PubsubChannel,
  PubsubService,
  PubsubScope,
  ReadEarningEventResponse,
} from '~/domain/pubsub/pubsub.type';
import { jsonParse } from '~/shared/helpers';
import {
  FeedbackPortal,
  Toast,
  ToastProps,
  useTransientState,
} from '@setel/web-ui';
import { trackUserEvent } from '~/shared/analytics';
import { EVENT_CHANNELS, EVENT_TYPES } from '~/shared/analytics.events';
import { useGetLoginAccountAnalytics } from '~/domain/account-settings/account-settings.queries';

export const MesraSummary: FC<React.PropsWithChildren<unknown>> = () => {
  useZendeskTagEffect(ZendeskTagsName.LOYALTY_CARD_DETAIL);

  const [toast, setToast] = useTransientState<ToastProps>(
    { show: false, message: '' },
    5000
  );

  /** fetch data from BE */
  const { data } = useQuery('getCard', getCard, {
    onError: defaultOnError,
  });
  const { data: dataBalance, refetch: refetchBalance } = useQuery(
    'getMesraBalance',
    getMesraBalance,
    {
      onError: defaultOnError,
    }
  );
  const { data: loginProfile } = useLoginProfile();
  const { data: channel } = usePubsubSubscribe(
    {
      service: PubsubService.Points,
      operationId: PubsubChannel.ReadEarning,
      scope: PubsubScope.User,
      userId: loginProfile?.sub,
    },
    {
      enabled: !!data && !!loginProfile?.sub,
      onError: defaultOnError,
    }
  );
  const { data: accountAnalytic } = useGetLoginAccountAnalytics({
    enabled: !!data,
    onError: defaultOnError,
  });

  /** Evenr sourcing handlers */
  useEffect(() => {
    let eventSource: EventSource | undefined;
    if (channel?.subUrl) {
      eventSource = new EventSource(channel.subUrl);
      eventSource.onmessage = (e) => {
        const message = jsonParse<ReadEarningEventResponse>(e.data);

        if (message && typeof message !== 'string') {
          const commonMessage = {
            card_number: data?.cardNumber ?? message.cardNumber,
            card_status: data?.status ?? message.status,
            amount: message.amount,
            point_amount: message.pointsAmount,
          };

          if (message.status === TransactionStatuses.SUCCESSFUL) {
            refetchBalance();

            setToast({
              show: true,
              variant: 'success',
              message: `${message.pointsAmount} Mesra points collected`,
            });

            if (accountAnalytic?.distinctId) {
              trackUserEvent(
                EVENT_TYPES.reward_scan_mesra_barcode_success,
                {
                  distinct_id: accountAnalytic.distinctId,
                  ...commonMessage,
                },
                [EVENT_CHANNELS.MIXPANEL]
              );
            }
          } else {
            setToast({
              show: true,
              variant: 'error',
              message: 'Failed to collect Mesra points, please try again',
            });

            if (accountAnalytic?.distinctId) {
              trackUserEvent(
                EVENT_TYPES.reward_scan_mesra_barcode_failed,
                {
                  distinct_id: accountAnalytic.distinctId,
                  ...commonMessage,
                },
                [EVENT_CHANNELS.MIXPANEL]
              );
            }
          }
        }
      };
    }
    return () => {
      eventSource?.close();
    };
  }, [
    channel?.subUrl,
    accountAnalytic?.distinctId,
    data?.cardNumber,
    data?.status,
  ]);

  if (!data) return null;
  return (
    <div className="flex flex-col items-center">
      <Card hideBorderOnMobile>
        <ProfileContentHeader title="Mesra card" />
        <CardContent className="flex justify-between flex-col-reverse md:flex-row px-2 md:px-10">
          <MesraInfo
            {...data}
            pointExpiryDates={dataBalance?.pointsExpiryDates}
            className="w-full md:w-1/2 pt-4 md:pt-0"
          />
          <MesraCard card={data} cardBalance={dataBalance} />
        </CardContent>
        <LinkOrActivateCard
          isPhysicalCard={data?.isPhysicalCard}
          isPointRedemptionEnabled={data?.isPointRedemptionEnabled}
          status={data?.status}
        />
        <ChatWithUs className="block md:hidden self-center mb-16" />
      </Card>
      <ChatWithUs className="hidden md:block mt-12" />

      <FeedbackPortal className="bottom-auto top-0 mt-2 z-[50]">
        <Toast {...toast} />
      </FeedbackPortal>
    </div>
  );
};
