import { Input } from '~/components';
import { IdIdentifier, switchToPhysicalCard } from '~/domain/card';
import { ChatWithUs } from '~/features/ChatWithUs';
import { FormField } from '~/features/Formik';
import { useFormik } from 'formik';
import { Button } from '@setel/web-ui';
import * as Yup from 'yup';
import { mesraNumber } from './validation';
import { onlyValidationError, defaultOnError } from '~/domain/error';

type ValidateMesraProps = {
  onContinue(): void;
  onStayWithVirtual(): void;
  idIdentifier: IdIdentifier;
};

const validationSchema = Yup.object({
  cardNumber: mesraNumber,
});

export const ValidateMesra = ({
  onContinue,
  onStayWithVirtual,
  idIdentifier,
}: ValidateMesraProps) => {
  const formik = useFormik({
    validationSchema,
    initialValues: {
      cardNumber: '',
      ...idIdentifier,
    },
    onSubmit: (data) =>
      switchToPhysicalCard(data)
        .then(onContinue)
        .catch(
          onlyValidationError(({ fields }) => {
            formik.setErrors(fields);
          })
        )
        .catch(defaultOnError),
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="flex flex-col items-center px-2"
    >
      <h1 className="text-2xl text-gray-800 text-center font-medium mb-2">
        We found existing Mesra card link to your <br /> IC number
      </h1>
      <p className="text-center text-gray-medium text-regular">
        Enter your mesra card number to verify
      </p>

      <FormField
        className="mt-6 mb-2"
        {...formik}
        label="Mesra card number"
        name="cardNumber"
        placeholder="Enter mesra card number"
        component={Input}
      />

      <div className="relative w-full">
        <Button
          type="submit"
          isLoading={formik.isSubmitting}
          className="w-full"
        >
          Link existing card
        </Button>

        <div className="relative">
          <p className="text-gray-light text-regular font-medium text-center bg-white px-2 absolute  transform translate-x-1/2 translate-y-1/2 bottom-1/2 right-1/2">
            OR
          </p>
          <div className="w-full h-px bg-gray-400 my-6" />
        </div>

        <Button
          type="button"
          onClick={onStayWithVirtual}
          className="w-full"
          variant="outline"
        >
          Stay with virtual card
        </Button>
      </div>
      <ChatWithUs className="mt-16" />
    </form>
  );
};
