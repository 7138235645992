import { MesraTransactionItem, TransactionStatuses } from '~/domain/card';
import { InfoStatus } from '~/components/Info';

export const MesraTransactionTitleDetail = ({
  title,
  status,
}: Pick<MesraTransactionItem, 'title' | 'status'>) => {
  return (
    <div className="py-6 px-4 md:pt-6 md:px-16 md:pb-12">
      {status === TransactionStatuses.PENDING ? (
        <InfoStatus
          className="flex justify-center items-center pb-1"
          variant="bg-warning"
        >
          pending
        </InfoStatus>
      ) : status === TransactionStatuses.FAILED ? (
        <InfoStatus
          className="flex justify-center items-center pb-1"
          variant="bg-error"
        >
          failed
        </InfoStatus>
      ) : (
        <></>
      )}
      <p className="text-center font-normal">{title}</p>
    </div>
  );
};
