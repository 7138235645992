import { Info } from '~/components/Info';
import { getCard, Mesra, MesraStatus as MesraStatusEnum } from '~/domain/card';
import { ChatWithUsAndTel } from '~/features/ChatWithUs';
import { FC, useEffect, useState } from 'react';
import * as React from 'react';
import { useQuery } from 'react-query';

export type ProcessingProps = {
  onActivated: () => void;
};

const REFETCH_INTERVAL = 5000;

export const Processing: FC<React.PropsWithChildren<ProcessingProps>> = ({
  onActivated,
}) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => setEnabled(true), REFETCH_INTERVAL);
    return () => clearTimeout(id);
  }, []);

  useQuery('getCard', getCard, {
    refetchInterval: REFETCH_INTERVAL,
    enabled,
    onSuccess: ({ status }: Mesra) => {
      if (status === MesraStatusEnum.ACTIVE) {
        setEnabled(false);
        onActivated();
      }
    },
  });

  return ProcessingBody;
};

const SpotPhone = (props: { className?: string }) => (
  <img src="/assets/spot-phone.svg" alt="" {...props} />
);

export const ProcessingBody = (
  <div className="flex flex-col items-center px-2">
    <Info
      largeTitle
      svg={SpotPhone}
      title="Processing"
      subTitle="Give us a few minutes while we process your request of activating your Mesra card for redemption with Setel"
    />
    <ChatWithUsAndTel className="mt-16" />
  </div>
);
