import { FC } from 'react';
import * as React from 'react';
import { MesraTransactionItem } from '~/domain/card';
import { MesraTransactionDetailLine } from './MesraTransactionDetailLine';
import { MesraTransactionDetailSection } from './MesraTransactionDetailSection';

export const MesraTransactionMerchantDetail: FC<
  React.PropsWithChildren<
    Partial<
      Pick<
        MesraTransactionItem,
        'issuedBy' | 'merchantName' | 'receiverCardNumber' | 'senderCardNumber'
      >
    >
  >
> = ({ issuedBy, merchantName, receiverCardNumber, senderCardNumber }) => {
  const cardDetails = receiverCardNumber
    ? (receiverCardNumber as unknown as string).replace(
        /(\d{6})(\d{7})(\d{4})/,
        '$1 ******* $3'
      )
    : (senderCardNumber as unknown as string).replace(
        /(\d{6})(\d{7})(\d{4})/,
        '$1 ******* $3'
      );

  return (
    <MesraTransactionDetailSection className="py-6">
      <MesraTransactionDetailLine
        left={<span className="text-gray-light">Issued by</span>}
        right={<span className="uppercase">{issuedBy}</span>}
        className="pb-4 last:pb-0"
      />
      {merchantName && (
        <MesraTransactionDetailLine
          left={<span className="text-gray-light">Merchant name</span>}
          right={<span className="capitalize">{merchantName}</span>}
          className="pb-4 last:pb-0"
        />
      )}
      <MesraTransactionDetailLine
        left={<span className="text-gray-light">Mesra card</span>}
        right={cardDetails}
        className="pb-4 last:pb-0"
      />
    </MesraTransactionDetailSection>
  );
};
