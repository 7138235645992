import { Input, Select } from '~/components';
import {
  activateMesra,
  IdIdentifier,
  searchCard,
  SearchMesraResponse,
} from '~/domain/card';
import { defaultOnError, onlyValidationError } from '~/domain/error';
import {
  IdentityType,
  IdentityTypeLabel,
  updateProfile,
} from '~/domain/profile';
import { FormField } from '~/features/Formik';
import { FormikErrors, useFormik } from 'formik';
import { Button } from '@setel/web-ui';
import * as Yup from 'yup';
import { idIdentifier } from './validation';
import { useEffect } from 'react';
import { RootNavigationRoute } from '~/features/Navigation';
import { trackUserEvent } from '~/shared';
import { EVENT_CHANNELS, EVENT_TYPES } from '~/shared/analytics.events';

type SearchMesraProps = {
  onSuccess(data: SearchMesraResponse): void;
  initialErrors?: FormikErrors<IdIdentifier>;
  error?: string;
};

const validationSchema = Yup.object().shape(idIdentifier);

export const SearchMesra = ({
  onSuccess,
  initialErrors,
  error,
}: SearchMesraProps) => {
  const formik = useFormik({
    initialValues: {
      idType: IdentityType.IC_NUMBER,
      idNumber: '',
    },
    validationSchema,
    initialErrors,
    onSubmit: (data) => {
      location.pathname ===
        RootNavigationRoute.MESRA_CARD_REGISTER_LANDING_PAGE &&
        trackUserEvent(
          EVENT_TYPES.cardless_mesra_continue_activate_mesra_card,
          {},
          [EVENT_CHANNELS.MIXPANEL]
        );
      searchCard(data)
        .then((response) => {
          if (response.cardsFound !== 0) return response;

          return updateProfile({
            identityType: data.idType,
            identityNumber: data.idNumber,
          })
            .then(() => activateMesra(data))
            .then(() => response);
        })
        .then(onSuccess)
        .catch(
          onlyValidationError(({ fields }) => {
            formik.setErrors(fields);
          })
        )
        .catch(defaultOnError);
    },
  });

  useEffect(() => {
    location.pathname ===
      RootNavigationRoute.MESRA_CARD_REGISTER_LANDING_PAGE &&
      trackUserEvent(
        EVENT_TYPES.cardless_mesra_pageview_activate_mesra_card,
        {},
        [EVENT_CHANNELS.MIXPANEL]
      );
  }, []);

  const idTypeOptions = Object.values(IdentityType).map((value) => ({
    value,
    label: IdentityTypeLabel[value],
  }));

  const idNumberLabel = idTypeOptions.find(
    ({ value }) => value === formik.values.idType
  )?.label;

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="flex flex-col items-center px-2"
    >
      <h1 className="text-xl font-epilogue font-semibold text-gray-800 text-center mb-4">
        Activate Mesra Rewards account
      </h1>
      <p className="text-center text-gray-medium text-regular mb-10">
        Activate your account to start
        <br /> redeeming points.
      </p>

      <FormField
        {...formik}
        label="ID type"
        name="idType"
        componentParams={{
          options: idTypeOptions,
        }}
        useSetValue
        component={Select}
      />
      <FormField
        {...formik}
        label={idNumberLabel}
        name="idNumber"
        placeholder="Ex:128271291291"
        component={Input}
      />
      {error && <p className="text-center text-error text-regular">{error}</p>}
      <Button
        isLoading={formik.isSubmitting}
        className="w-full mb-4"
        disabled={!formik.isValid}
        type="submit"
      >
        Continue
      </Button>
    </form>
  );
};
