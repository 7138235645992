import { UseQueryOptions, useQuery } from 'react-query';
import { getAnalytics } from './account-settings.service';
import { AccountAnalyticsResponse } from './account-settings.type';
import { AxiosError } from 'axios';
import { useLoginProfile } from '~/features/Auth/hooks/useUserProfile';

export const useGetLoginAccountAnalytics = <Result = AccountAnalyticsResponse>(
  config?: UseQueryOptions<any, AxiosError<any>, Result, (string | undefined)[]>
) => {
  const { data: loginProfile } = useLoginProfile();
  return useQuery(
    ['account-analytics', loginProfile?.sub],
    () => (loginProfile?.sub ? getAnalytics(loginProfile?.sub) : null),
    config
  );
};
