import { apiClient } from '../../shared/axios';
import {
  AccountAnalyticsResponse,
  AccountSettings,
  CreateWalletInput,
  CreateWalletResponse,
} from './account-settings.type';

export const getAccountSettings = () =>
  apiClient
    .get<AccountSettings>('/idp/accountsSettings')
    .then((resp) => resp.data);

export const subscribeToEmailMarketing = () =>
  apiClient.get('/idp/accounts/emails/subscribe').then((resp) => resp.data);

export const unsubscribeFromEmailMarketing = () =>
  apiClient.get('/idp/accounts/emails/unsubscribe').then((resp) => resp.data);

export const getWalletStatus = () =>
  apiClient
    .get<{ status: 'linked' | 'not-registered' }>('/wallets/wallets/status')
    .then((resp) => resp.data);

export const createWallet = (params: CreateWalletInput) =>
  apiClient
    .post<CreateWalletResponse>('/wallets/wallets', params)
    .then((resp) => resp.data);

export const getAnalytics = (userId: string) =>
  apiClient
    .get<AccountAnalyticsResponse>(`/idp/accounts/${userId}/analytics`)
    .then((resp) => resp.data);

export const createWalletPin = (data: { pin: string }) => {
  return apiClient.post<{ id: string }>(`/payments/wallets`, data);
};

export const changeWalletPin = (data: {
  newPin: string;
  otpToken: string;
  phoneToken: string;
  emailToken: string;
}) => {
  return apiClient.post(
    `/payments/wallets/change-wallet-pin-by-otp-token`,
    data
  );
};
